@tailwind base;
@tailwind components;
@tailwind utilities;
@font-face {
  font-family: 'SF Pro Display';
  src: url('../public/fonts/Sf-pro-display/SF-Pro-Display-Regular.otf') format('opentype');
  font-weight: 400;
}
@font-face {
  font-family: 'SF Pro Display';
  src: url('../public/fonts/Sf-pro-display/SF-Pro-Display-Semibold.otf') format('opentype');
  font-weight: 600;
}
@font-face {
  font-family: 'SF Pro Display';
  src: url('../public/fonts/Sf-pro-display/SF-Pro-Display-Bold.otf') format('opentype');
  font-weight: 700;
}
html {
  font-family: 'SF Pro Display', sans-serif;
  padding-top: env(safe-area-inset-top);
  padding-bottom: env(safe-area-inset-bottom);
  padding-left: env(safe-area-inset-left);
  padding-right: env(safe-area-inset-right);
  background-color: white; /* Ustaw tło na białe */
 
}

body {
  font-family: 'SF Pro Display', sans-serif;
  overflow: hidden;
}

@media (max-width: 640px) {
  body {
    overflow: visible;
  }
}


/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 504px; Użyj całej wysokości widoku */
}
.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid #333;
  border-radius: 50%;

  animation: spin 1s linear infinite;
  margin: auto; /* Wyśrodkuj spinner w kontenerze */
  
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


.radial-gradient {
  background: radial-gradient(circle at 0% 0%, #91dd55, #beffa2);
}

/* .overflow-auto::-webkit-scrollbar {
  width: 5px;
}
.overflow-auto::-webkit-scrollbar-thumb {
  background-color: #27272a; /* Twój wybrany kolor */
  

  #scrollContainer {
    /* Stylowanie suwaka przewijania */
    scrollbar-width: thin; /* Dla innych przeglądarek */
    scrollbar-color: #27272a transparent; /* Dla innych przeglądarek */

    /* Ukrycie suwaka przewijania */
    overflow-y: auto;
}

#scrollContainer::-webkit-scrollbar {
    width: 5px;
}

/* Kolor suwaka przewijania */
#scrollContainer::-webkit-scrollbar-thumb {
    background-color: #27272a; /* Twój wybrany kolor */
}

/* Ukrycie suwaka przewijania dla innych przeglądarek */
#scrollContainer::-webkit-scrollbar-track {
    background-color: transparent;
}

  #scrollContainer2 {
    /* Stylowanie suwaka przewijania */
    scrollbar-width: thin; /* Dla innych przeglądarek */
    scrollbar-color: #27272a transparent; /* Dla innych przeglądarek */

    /* Ukrycie suwaka przewijania */
    overflow-y: auto;
    /* padding-right: 5px; Szerokość suwaka przewijania */
}

#scrollContainer2::-webkit-scrollbar {
    width: 5px;
}

/* Kolor suwaka przewijania */
#scrollContainer2::-webkit-scrollbar-thumb {
    background-color: #27272a; /* Twój wybrany kolor */
}

/* Ukrycie suwaka przewijania dla innych przeglądarek */
#scrollContainer2::-webkit-scrollbar-track {
    background-color: transparent;
}


/* Dodatkowa klasa do ukrycia suwaka przewijania */
.hide-scrollbar {
    scrollbar-width: none; /* Dla innych przeglądarek */
    -ms-overflow-style: none; /* Dla IE i Edge */
    overflow-y: auto;
}


/* Dla przeglądarek opartych na Webkit (Safari, Chrome) */
::-webkit-scrollbar {
    width: 10px; /* Szerokość suwaka */
}

::-webkit-scrollbar-track {
    background: #ffffff; /* Kolor tła */
}

::-webkit-scrollbar-thumb {
    background: #27272a; /* Kolor suwaka */
}

::-webkit-scrollbar-thumb:hover {
    background: #27272a; /* Kolor suwaka przy najechaniu */
}

/* Dla przeglądarki Firefox */
* {
    scrollbar-width: thin; /* Szerokość suwaka */
    scrollbar-color: #27272a #ffffff; /* Kolor suwaka i tła */
}


@keyframes pulse-border {
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
}

.highlight {
  animation: pulse-border 1s infinite; /* Odtwarzamy animację bez końca */
}


/* Nadpisanie stylów focus i hover dla ant-design */
.ant-input:focus,
.ant-input:hover,
.ant-input-password:focus,
.ant-input-password:hover,
.ant-input-password:active,
.ant-select-focused .ant-select-selector,
.ant-select:hover .ant-select-selector,
.ant-btn:focus,
.ant-btn:hover,
.ant-btn:focus-within,
.ant-switch-checked,
.ant-switch:hover {
    border-color: #a0a0a0 !important; /* Szary kolor ramki */
    box-shadow: none !important;
    outline: none !important;
}
.ant-btn:focus,
.ant-btn:hover
{
  background-color: #f0f0f0 !important;
  outline: none !important;
}
.ant-input-affix-wrapper:focus-within {
  border: 1px solid #a0a0a0 !important;
  box-shadow: none !important;
}
/* Nadpisanie stylów dla przycisków */
.ant-btn-primary:hover,
.ant-btn-primary:focus {
    background-color: #555555 !important; /* Szary kolor tła */
    border-color: #555555 !important; /* Szary kolor obramowania */
}

/* Dla wszystkich innych elementów focus */
/* *:focus {
    outline-color: #a0a0a0;
    outline-width: 2px;
    outline-style: solid;
} */

/* Nadpisanie stylów globalnych dla hover */
 /* Nadpisanie stylów dla ant-design Switch (przełącznika) */
.ant-switch-checked {
  background-color: #a0a0a0 !important; /* Szary kolor tła przełącznika w stanie aktywnym */
  border-color: #a0a0a0 !important; /* Szary kolor obramowania */
}

/* Zmiana koloru przełącznika w stanie hover */
.ant-switch-checked:hover {
  background-color: #888888 !important; /* Ciemniejszy szary w stanie hover */
}

/* Nadpisanie stylów dla przełącznika, gdy nie jest aktywny */
.ant-switch {
  background-color: #f0f0f0; /* Jasnoszary kolor przełącznika, gdy jest wyłączony */
  border-color: #d9d9d9; /* Jasnoszary kolor obramowania */
}

/* Zmiana koloru przełącznika w stanie hover, gdy jest wyłączony */
.ant-switch:hover {
  background-color: #e0e0e0; /* Ciemniejszy szary kolor w stanie hover, gdy jest wyłączony */
}

/* Styl dla przełącznika w stanie focus */
.ant-switch:focus {
  box-shadow: 0 0 0 2px rgba(160, 160, 160, 0.5) !important; /* Szara poświata */
}

.rbc-event.rbc-selected, 
.rbc-day-slot,
.rbc-selected.rbc-background-event, 
.rbc-event,
.rbc-event-allday
{
  background-color: #fff !important;
  padding: 0 !important;
}


/* Styl dla kontenera wydarzeń, aby wyświetlać wszystko w kolumnie */
.rbc-day-slot .rbc-events-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

/* Zmniejszamy marginesy i padding dla każdego wydarzenia */
.rbc-event {
  margin: 0 !important;
  padding: 0.5em;
}

/* Wymuszamy ustawienie każdego wydarzenia jeden pod drugim, dzieląc dostępną wysokość */
.rbc-row-segment {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: auto;
}

.rbc-row {
  height: 24px;
  margin-top: 2px;
}
